import { useEffect, useState } from "react";
import { Dynamic } from "../api/checkout/dynamic";

export const useDynamicHook = ({ hookName, page = 1, limit = 20 }) => {
  const [dynamicData, setDynamicData] = useState();

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data },
        } = await Dynamic.Content.getAll({
          hookName,
          page,
          limit,
        });
        if (!data) {
          return;
        }
        setDynamicData(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return { dynamicData };
};
