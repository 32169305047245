import { Link, useMatch, useResolvedPath } from "react-router-dom";
import React from "react";

export const SidebarTab = ({ path, title, fakePath }) => {
  const resolved = useResolvedPath(path);
  const active = useMatch({ path: resolved?.pathname });

  return (
    <li>
      <Link
        className={`mb-2 cursor-pointer h-12 px-4 flex items-center rounded-lg w-full ${
          (path === "/" && window?.location?.href.includes("collection")) ||
          active
            ? "bg-indigo-600 font-medium"
            : "font-medium hover:bg-white/5"
        }`}
        style={{ fontSize: 15 }}
        to={path}
      >
        {title}
      </Link>
    </li>
  );
};
