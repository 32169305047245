import { Footer } from "../components/Footer";
import { useFetch } from "../hooks/useFetch";
import { Customer } from "../api/customer/customer";
import Table from "../components/Table";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";
import { Container } from "../components/Container";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Layout } from "./Layout";
import { Button } from "../components/Button";

timeago.register("fa", fa);

const statusProcess = {
  PENDING_PAYMENT: "جدید",
  COMPLETED: (
    <div className="rounded-xl text-white/70 bg-violet-600 py-1 px-3 inline-flex text-xs">
      تکمیل شده
    </div>
  ),
  FAILED: (
    <div className="rounded-xl text-white/70 bg-red-600 py-1 px-3 inline-flex text-xs">
      خطا
    </div>
  ),
  PROCESSING: (
    <div className="rounded-xl text-white/70 bg-gray-600 py-1 px-3 inline-flex text-xs">
      در حال پردازش
    </div>
  ),
  CANCELED: (
    <div className="rounded-xl text-white/70 bg-gray-600 py-1 px-3 inline-flex text-xs">
      کنسل شده
    </div>
  ),
};

export const model = [
  {
    Header: "شناسه",
    accessor: "id",
    Cell: ({ value }) =>
      value && <span className="font-semibold">{value}</span>,
  },
  {
    Header: "وضعیت",
    accessor: "status",
    Cell: ({ value }) => statusProcess[value] || value,
  },
  {
    Header: "تاریخ",
    accessor: "createdAt",
    Cell: ({ value }) =>
      value && (
        <TimeAgo
          datetime={value}
          locale="fa"
          className="dono-dock-time px-1 mb-0"
        />
      ),
  },
  {
    Header: "لینک",
    accessor: "link",
    Cell: ({ value, row }) => (
      <Link
        className="text-violet-500 flex items-center"
        to={`/verify?orderId=${row?.original?.id}`}
      >
        صفحه سفارش
        <ChevronLeftIcon className="w-4 h-4" />
      </Link>
    ),
  },
];

export const Orders = () => {
  const {
    fetchData,
    data: orders,
    loading,
  } = useFetch((p) => Customer.getOrders({ ...p }));

  return (
    <Layout>
      <div
        className={`bg-white/5 rounded-lg px-7 py-10 verify-bg flex items-center justify-center h-64 mb-10`}
      >
        <div className="flex flex-col gap-y-4 items-center">
          {
            <>
              <h2 className="font-bold text-2xl">سفارش های شما</h2>
              <p className="opacity-50 max-w-md text-center">
                لیست خرید های خود را میتوانید در این صفحه مشاهده کنید.
              </p>
            </>
          }
        </div>
      </div>

      <div className="mb-8">
        <Table
          data={orders?.data}
          columns={model}
          loading={loading}
          pageCount={orders?.pagination?.total}
          fetchData={fetchData}
        />
      </div>
    </Layout>
  );
};
