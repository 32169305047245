import { useModal } from "../hooks/useModal";
import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { Auth } from "../api/customer/auth";
import { toast } from "../helpers/toast";
import OtpInput from "react-otp-input";
import "./Login.scss";
import { Twitch } from "../api/twitch";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { noahConfig } from "../config/noah";

const EmailForm = ({ setCookie, closeModal }) => {
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState();
  const [code, setCode] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [easeOfUse, setEaseOfUse] = useState(false);

  const handleOtp = (otp) => setCode(otp);

  useEffect(() => {
    if (code.length === 4) {
      if (!easeOfUse) {
        handleVerifyLogin();
        setEaseOfUse(true);
      }
    }
  }, [code]);

  const handleVerifyLogin = async () => {
    try {
      if (!emailSent) {
        return;
      }

      setLoading(true);
      const { data } = await Auth.verifyLogin({ id: emailSent?.id, code });
      if (!data) {
        return;
      }

      const { token } = data;
      if (!token) {
        return toast.error("مشکلی پیش آمد.");
      }
      const production = process.env.REACT_APP_NODE_ENV === "production";
      setCookie("noahUserToken", token, {
        domain: production ? ".dono.gg" : "localhost",
      });
      toast.success("با موفقیت وارد شدید.");
      closeModal();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    try {
      if (!email) {
        return;
      }
      setLoading(true);
      const { data } = await Auth.login(email);
      if (!data) {
        return;
      }

      const { sent, resendIn } = data;

      if (sent) {
        toast.success("لطفا اینباکس ایمیل خود را چک کنید.");
      } else {
        return toast.error(
          `به تازگی ایمیل ورود برای شما ارسال شده است. مجددا ${
            resendIn && parseInt(resendIn / 1000)
          } ثانیه دیگر تلاش کنید.`
        );
      }

      setEmailSent(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="-mt-2 mb-4">
        <p className="text-15px text-gray-500">
          {!emailSent && <span>لطفا ایمیل خود را وارد کنید.</span>}
          {emailSent && <span>لطفا اینباکس ایمیل خود را چک کنید.</span>}
        </p>
      </div>
      {!emailSent && (
        <div className="mb-4">
          <input
            id="email"
            type="text"
            className="py-2 px-3 border-2 bg-transparent border-white/10 block w-full rounded-md shadow-sm "
            placeholder="آدرس ایمیل شما.."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      )}
      {emailSent && (
        <div className="mb-4">
          <div className="otp-form">
            <OtpInput
              value={code}
              onChange={handleOtp}
              numInputs={4}
              separator={<span>-</span>}
            />
          </div>
        </div>
      )}
      <Button
        type="primary"
        block
        onClick={emailSent ? handleVerifyLogin : handleLogin}
        loading={loading}
      >
        ورود
      </Button>
    </>
  );
};

export const LoginOptions = ({ setCookie, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [loginMethod, setLoginMethod] = useState<"twitch" | "email">();

  const handleTwitchLogin = async () => {
    try {
      setLoading(true);
      const { data } = await Auth.loginWithTwitch({
        redirectUrl: noahConfig.BASE_URL,
      });
      if (!data) {
        return;
      }
      window.location.assign(data?.url);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-3">
      {!loginMethod && (
        <>
          <Button
            type="secondary"
            block
            onClick={() => setLoginMethod("email")}
          >
            ورود با ایمیل
          </Button>
          <Button
            type="twitch"
            block
            onClick={handleTwitchLogin}
            loading={loading}
          >
            ورود با توییچ
          </Button>
        </>
      )}
      {loginMethod === "email" && (
        <EmailForm setCookie={setCookie} closeModal={closeModal} />
      )}
      {loginMethod && (
        <a
          className="text-gray-500 block text-center cursor-pointer text-15px font-medium"
          onClick={() => setLoginMethod(undefined)}
        >
          ورود با روش دیگر
        </a>
      )}
    </div>
  );
};

export const Login = ({ setShowLogin, setCookie }) => {
  const [params] = useSearchParams();
  const manualLoginTrigger = params.get("triggerLogin");
  const navigate = useNavigate();

  const closeModal = () => {
    setShowLogin(false);
    if (manualLoginTrigger) {
      navigate("/");
    }
  };
  const { Modal } = useModal({
    title: "ورود یا ثبت نام",
    description: "برای استفاده از سایت لازم است تا در سایت ثبت نام کنید.",
    closeModal,
  });

  return (
    <Modal>
      <LoginOptions setCookie={setCookie} closeModal={closeModal} />
    </Modal>
  );
};
