import { useEffect, useRef, useState } from "react";

export const useFetch = (endpointPromise) => {
  const [endpointData, setEndpointData] = useState<any>();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const firstUpdate = useRef(true);

  const fetchData = ({ pageIndex, pageSize }) => {
    setPageIndex(pageIndex + 1);
    setPageSize(pageSize);
  };

  const getData = async () => {
    try {
      setLoading(true);
      if (!pageIndex || !pageSize) {
        return;
      }
      const { data: paymentMethodsData } = await endpointPromise({
        page: pageIndex,
        limit: pageSize,
      });
      if (!paymentMethodsData) {
        return;
      }
      const { data, pagination } = paymentMethodsData;
      if (!data) {
        return;
      }
      setEndpointData({ data, pagination });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }

        await getData();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }

        await getData();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [pageIndex, pageSize]);

  return {
    data: endpointData,
    fetchData,
    pageSize,
    loading,
    refresh: getData,
  };
};
