import { get, post } from "../../config/checkout";

export const Order = {
  create: ({ id, metadata, paymentMethodId }) =>
    post(`order`, {
      products: [
        {
          id,
          metadata,
        },
      ],
      paymentMethodId,
    }),
  getById: (id) => get(`order/${id}`),
};
