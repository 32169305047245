import { CheckCircleIcon } from "@heroicons/react/solid";
import { Spinner } from "../../components/Spinner";
import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Customer } from "../../api/customer/customer";

export const VerifyOrderProduct = ({ id, title, status, orderId }) => {
  const [items, setItems] = useState();

  const handleInitFetch = async () => {
    try {
      const { data } = await Customer.getOrderProduct({
        orderProductId: id,
        orderId,
      });
      if (!data) {
        return;
      }
      setItems(data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      className={`${
        status === "COMPLETED" ? "bg-white/5" : "border-2 border-white/5"
      } rounded-lg px-6 py-3.5`}
    >
      <div className={`flex justify-between items-center`}>
        <div className="flex gap-x-2 items-center" style={{ direction: "rtl" }}>
          <span className="text-sm opacity-70">#{title}</span>
        </div>
        <div>
          {status === "COMPLETED" && (
            <div className="flex items-center gap-x-2 bg-indigo-600 px-5 py-2 rounded-xl">
              <CheckCircleIcon className="w-6 h-6 text-white" />
              موفق
            </div>
          )}
          {status === "PENDING" && (
            <div className="flex items-center gap-x-4 bg-white/10 px-5 py-2 rounded-xl">
              <Spinner />
              در حال پردازش
            </div>
          )}
        </div>
      </div>
      {status === "COMPLETED" && (
        <div>
          <hr className="opacity-5 my-4" />
          {items ? (
            <div className="flex flex-col gap-y-4">
              <span>کد های شما در زیر لیست شده اند:</span>
              {items?.metadata?.pins?.map(({ pin }, index) => (
                <>
                  <div
                    className="flex gap-x-3 items-center"
                    style={{ direction: "ltr" }}
                  >
                    {items?.metadata?.pins?.length > 1 && (
                      <span className="text-2xl font-bold">{index + 1}.</span>
                    )}
                    <span className="bg-black/10 rounded-lg py-1 px-2 flex items-center text-white/80">
                      {pin?.replace(" .", "")}
                    </span>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-y-3 font-medium">
              <span className="opacity-80">
                کد های سفارش شما آماده تحویل هستند.
              </span>
              <div>
                <div
                  className="bg-black/20 rounded-lg inline-flex px-4 py-2 cursor-pointer animate-pulse font-semibold"
                  onClick={handleInitFetch}
                >
                  مشاهده کد
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
