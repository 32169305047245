import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "../helpers/toast";
import { noahConfig } from "./noah";

const cookies = new Cookies();
const userToken = () => "Bearer " + cookies?.get("noahUserToken");

const errorHandler = (error) => {
  toast.error(
    `${
      (error?.response?.data?.message.includes("TODO_EXCEPTION_MINIMUM_IS") &&
        `حداقل میزان خرید برای این استریمر ${
          error?.response?.data?.message?.split("TODO_EXCEPTION_MINIMUM_IS_")[1]
        } بیت می باشد.`) ||
      error?.response?.data?.message ||
      "مشکلی پیش آمد."
    }`
  );

  return Promise.reject({ ...error });
};

const instance = axios.create({
  baseURL: `https://checkout-api${
    process.env.REACT_APP_NODE_ENV === "production" ? "" : "-staging"
  }.9ah.ir/v1`,
  timeout: 60000,
  headers: {
    "noah-partner-id": noahConfig.NOAH_PARTNER_ID,
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => errorHandler(error)
);

instance.interceptors.request.use(
  function (config) {
    if (cookies?.get("noahUserToken")) {
      config.headers["Authorization"] = userToken();
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const { get, post, put, delete: remove, patch } = instance;

export { get, post, put, remove, patch };
