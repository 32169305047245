import {
  CreditCardIcon,
  LibraryIcon,
  ShoppingCartIcon,
  TagIcon,
} from "@heroicons/react/outline";
import React from "react";
import { Home } from "../pages/Home";
import { Verify } from "../pages/Verify/Verify";
import { Orders } from "../pages/Orders";
import { Privacy } from "../pages/Privacy";
import { ContactUs } from "../pages/ContactUs";
import { Collection } from "../pages/Collection";

export const routes = [
  {
    title: "فروشگاه",
    id: "home",
    path: "/",
    Element: <Home />,
  },
  {
    title: "محصولات",
    id: "products",
    path: "/products/*",
    Element: <Home />,
    hidden: true,
  },
  {
    title: "کالکشن",
    id: "collection",
    path: "/collection/:id",
    Element: <Collection />,
    hidden: true,
  },
  {
    title: "حریم خصوصی",
    id: "privacy",
    path: "/privacy",
    Element: <Privacy />,
  },
  {
    title: "سفارش های من",
    id: "api",
    path: "/my-orders",
    Element: <Orders />,
  },
  {
    title: "پیگیری سفارش",
    id: "verify",
    path: "/verify",
    Element: <Verify />,
  },
  {
    title: "تماس با ما",
    id: "support",
    path: "/support",
    Element: <ContactUs />,
  },
];
