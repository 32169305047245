const production = process.env.REACT_APP_NODE_ENV === "production";

export const noahConfig = {
  NOAH_PARTNER_ID: production
    ? "b5497d15-7ae5-40d8-a1a8-163961a8773d"
    : "675d9249-5e3c-4372-8976-fbe026e8cf02",
  PAYMENT_METHOD_ID: production
    ? "4e2cee0c-4611-4721-9426-8530154d1af0"
    : "7c902049-9f36-465f-a06e-6cc8f4e3178c",
  BASE_URL: production ? "https://shop.dono.gg" : "http://localhost:3000",
};
