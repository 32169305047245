import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Tag } from "../api/checkout/tag";
import { Layout } from "./Layout";
import outlineShape from "../images/outline_shape.webp";
import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline";
import { ProductOption } from "../components/ProductOption";
import { classNames } from "../utils/classNames";
import { Skeleton } from "../components/Skeleton";
import { Button } from "../components/Button";
import { createOrder } from "../helpers/createOrder";
import delivery from "../images/delivery.svg";
import support from "../images/support.svg";
import { useDynamicHook } from "../hooks/useDynamicHook";
import { Spinner } from "../components/Spinner";
import { noahConfig } from "../config/noah";
import { useCookies } from "react-cookie";

export const Collection = () => {
  const { id } = useParams();
  const [groupProduct, setGroupProduct] = useState();
  const [variants, setVariants] = useState();
  const [selectedVariant, setSelectedVariant] = useState();
  const [quantity, setQuantity] = useState(1);
  const [params, setParams] = useSearchParams();
  const [product, setProduct] = useState<{ title: string; products: any[] }>();
  const [loading, setLoading] = useState<boolean>();
  const { dynamicData } = useDynamicHook({
    hookName: "PRODUCT_EXTENDED",
    limit: 50,
  });
  const [productExtended, setProductExtended] = useState();
  const [cookies] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (!dynamicData) {
      return;
    }
    const [targetDynamicData] = dynamicData?.filter(
      (data) => data?.content?.id === id
    );

    const { content } = targetDynamicData || {};

    if (!content) {
      return;
    }

    setProductExtended(content);
  }, [dynamicData]);

  const groupDefinitions = {
    amount: "انتخاب مقدار",
    region: "انتخاب ریجن",
  };

  useEffect(() => {
    if (!selectedVariant) {
      return;
    }

    const variants = [];
    for (const key of Object.keys(selectedVariant)) {
      variants.push(`${key}==${selectedVariant[key]}`);
    }

    const [matchedProduct] = variants?.reduce(
      (r, c) => {
        return r.filter((product) => product?.title.includes(c));
      },
      [...groupProduct?.products]
    );

    const variantUrlParams = new URLSearchParams();
    Object.keys(selectedVariant).forEach((variantKey) =>
      variantUrlParams.append(variantKey, selectedVariant[variantKey])
    );
    setParams(variantUrlParams);

    setProduct(matchedProduct);
  }, [selectedVariant]);

  useEffect(() => {
    (async () => {
      try {
        if (typeof id === "undefined") {
          return;
        }

        window.scrollTo(0, 0);

        setSelectedVariant(undefined);

        const { data } = await Tag.getGroupTagByName(id);
        if (!data) {
          return;
        }

        const expandedProducts = data?.products?.map((product) => {
          const newProduct = { ...product };
          const { title } = newProduct;
          const variants = title.split(";");
          for (const variant of variants) {
            const [key, value] = variant.split("==");
            newProduct[key] = value;
          }
          return newProduct;
        });

        const sortedExpandedData = expandedProducts?.sort(
          (a, b) => a?.amount - b?.amount
        );

        const newGroupProduct = {
          ...data,
          products: sortedExpandedData,
        };

        setGroupProduct(newGroupProduct);

        if (newGroupProduct?.products?.length === 0) {
          return;
        }

        const urlParams = Object.fromEntries(new URLSearchParams(params));

        const { title, product, ...defaultSelectedVariants } =
          newGroupProduct?.products[0];

        const finalVariants = {
          ...defaultSelectedVariants,
          ...(Object.keys(urlParams).length !== 0 && { ...urlParams }),
        };

        setSelectedVariant(finalVariants);

        const options = newGroupProduct?.products?.reduce((r, c) => {
          if (!c?.product?.active) {
            return r;
          }
          const { title } = c;
          const variants = title.split(";");
          for (const variant of variants) {
            const [key, value] = variant.split("==");
            r[key] = r[key]
              ? r[key].includes(value)
                ? r[key]
                : [...r[key], value]
              : [value];
          }
          return r;
        }, {});

        setVariants(options);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [id]);

  const handleQuantity = (number) => {
    if (quantity === 1 && number < 0) {
      return;
    }
    setQuantity((prevQuantity) => prevQuantity + number);
  };

  const handleCreateOrder = async () => {
    try {
      if (!cookies.noahUserToken) {
        return navigate("/?triggerLogin=true");
      }

      setLoading(true);

      await createOrder({
        id: product?.product?.id,
        metadata: { quantity: quantity },
        paymentMethodId: noahConfig.PAYMENT_METHOD_ID,
      });

      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Layout>
      <div className="flex justify-start w-full relative overflow-hidden mb-10 gap-x-8">
        <img
          src={productExtended?.cover}
          className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 opacity-40 self-start shrink-0"
        />
        {productExtended?.hero && (
          <div className="flex grow justify-center items-end min-h-[280px]">
            <img
              src={outlineShape}
              className="absolute top-8 -z-1 w-[184px] h-[323px] lg:w-[290px] lg:h-[508px] self-start shrink-0"
            />
            <div className="z-50">
              <img src={productExtended?.hero} className="max-h-[420px]" />
            </div>
            <img
              src={productExtended?.logo}
              className="absolute z-[200] bottom-5 max-w-[150px]"
            />
          </div>
        )}
        <div className="absolute inset-x-0 bottom-0 h-24 bg-gradient-to-t from-[#181717] to-transparent -mt-4 z-[100]" />
        <div className="absolute inset-0 bg-gradient-to-b from-[#181717] via-transparent to-[#181717] z-30" />
        <div className="absolute inset-y-0 right-0 w-52 bg-gradient-to-l from-[#181717] to-transparent" />
        <div className="absolute inset-y-0 left-0 w-2/6 bg-gradient-to-r from-[#181717] via-[#181717] to-transparent" />
        <div className="flex items-center w-full">
          <div className="relative z-50 flex flex-col banner-text-container items-start px-5 py-4 md:px-9 md:py-7 gap-y-2 mt-4 w-full">
            <div className="flex justify-between items-center w-full mb-2">
              <h1 className="text-2xl font-semibold">
                {productExtended?.seo_title}
              </h1>
            </div>
            <span className="opacity-60 leading-relaxed">
              - منطقه فعال سازی: {variants?.region?.join(" ")}
              {!variants?.region && (
                <div className="inline-flex relative -bottom-1.5">
                  <Spinner />
                </div>
              )}
              <br />- زمان تحویل محصول: تحویل به صورت آنی
              {productExtended?.platform && (
                <>
                  <br />- پلتفورم های قابل استفاده از این محصول:{" "}
                  {productExtended?.platform}
                </>
              )}
              <br />- لطفا قبل از خرید توضیحات محصول را مطالعه بفرمایید .
            </span>
          </div>
        </div>
      </div>

      <div className="flex gap-x-12">
        <div className="grow">
          {groupProduct?.description && (
            <>
              <h2 className="block font-semibold text-lg mb-2 text-white opacity-70">
                توضیحات
              </h2>
              <p className="text-white opacity-50 mb-10">
                {groupProduct?.description}
              </p>
            </>
          )}

          <div className="grid grid-cols-4 gap-x-10">
            <div className="flex justify-center md:flex-col md:mt-0">
              <div>
                <img src={delivery} className="mb-2 w-12" />
              </div>
              <div>
                <h2 className="text-lg font-semibold mb-1">تحویل آنی</h2>
                <p className="opacity-50 w-40 text-sm">
                  انجام سفارش شما در سریع ترین حالت ممکن
                </p>
              </div>
            </div>
            <div className="flex justify-center md:flex-col gap-x-4 md:gap-x-0 md:mt-0">
              <img src={support} className="mb-2 w-12" />
              <div>
                <h2 className="text-lg font-semibold mb-1">پشتیبانی آنلاین</h2>
                <p className="opacity-50 w-40 text-sm">
                  پشتیبانی همیشه آماده خدمتگزاری به شماست
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shrink-0 flex basis-[350px]">
          <div className="border border-white/10 py-4 px-5 rounded-xl flex flex-grow flex-col gap-y-4">
            {variants && !Object.keys(variants).length && (
              <span className="text-neutral-700">
                این محصول هیچ واریانتی ندارد.
              </span>
            )}

            {variants &&
              Object.keys(variants)?.map((variant) => {
                return (
                  <ProductOption
                    displayTitle={groupDefinitions[variant] || variant}
                    title={variant}
                    options={variants[variant]}
                    setSelectedVariant={setSelectedVariant}
                    value={selectedVariant && selectedVariant[variant]}
                  />
                );
              })}

            <div className="quantity flex flex-col gap-y-2">
              <span className="font-medium text-white opacity-50">تعداد</span>

              <div className="inline-flex">
                <div className="border border-white/50 rounded-lg px-1.5 py-1 flex gap-x-4 items-center select-none h-full">
                  <PlusSmIcon
                    className="w-5 h-5 text-primary cursor-pointer hover:opacity-70 transition"
                    onClick={() => handleQuantity(+1)}
                  />
                  <span className="flex items-center gap-x-1">
                    <span className="font-medium text-xl price">
                      {quantity}
                    </span>{" "}
                    <span className="text-white text-xs">عدد</span>
                  </span>
                  <MinusSmIcon
                    className={classNames(
                      "w-5 h-5  cursor-pointer hover:opacity-70 transition",
                      quantity !== 1 && "text-primary",
                      quantity === 1 && "text-neutral-400"
                    )}
                    onClick={() => handleQuantity(-1)}
                  />
                </div>
              </div>
            </div>

            <div>
              {!product?.product?.price && <Skeleton />}

              {product?.product?.price && (
                <div
                  className={classNames(
                    "flex flex-col lg:flex-row justify-between lg:mt-2 lg:items-center text-white"
                  )}
                >
                  <span className="font-medium">قیمت:</span>
                  <div className="flex gap-x-2 items-center">
                    <span className="text-2xl font-medium price">
                      {Math.round(
                        (product?.product?.price?.amount * quantity) / 10
                      ).toLocaleString()}
                    </span>
                    <span className="text-sm">تومان</span>
                  </div>
                </div>
              )}

              <div className="flex items-stretch grow mt-0 lg:mt-4 gap-x-4 flex-row-reverse">
                <div className="flex-grow">
                  <Button
                    type="primary"
                    block
                    onClick={handleCreateOrder}
                    disabled={loading || groupProduct?.products?.length === 0}
                  >
                    خرید و تحویل آنی
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="opacity-10 my-16"></hr>
    </Layout>
  );
};
