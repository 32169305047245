import { useContext } from "react";
import { ShowLoginContext } from "../App";
import { useCookies } from "react-cookie";
import { toast } from "../helpers/toast";
import { selfContext } from "../hooks/useProvideSelf";
import { Button } from "./Button";
import { MenuIcon, ShoppingCartIcon, UserIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export const HeaderTabs = ({ setMobileSidebarIsOpen }) => {
  const { showLogin, setShowLogin } = useContext(ShowLoginContext);
  const [cookies, setCookie, removeCookie] = useCookies();
  const handleRemoveTokens = () => {
    const production = process.env.REACT_APP_NODE_ENV === "production";
    console.log(production);
    removeCookie("noahUserToken", {
      path: "/",
      domain: production ? ".dono.gg" : "localhost",
    });
    toast.error("با موفقیت خارج شدید.");
  };
  const { twitchUser } = useContext(selfContext);

  const tabs = [
    {
      content: (
        <>
          {cookies?.noahUserToken ? (
            <div className="flex gap-6">
              {twitchUser && (
                <div className="flex gap-2.5 items-center">
                  <img src={twitchUser?.logo} className="h-6 rounded-full" />
                  <span>{twitchUser?.display_name}</span>
                </div>
              )}
              <Button type="secondary" onClick={handleRemoveTokens}>
                خروج
              </Button>
            </div>
          ) : (
            <Button type="secondary" onClick={() => setShowLogin(true)}>
              <UserIcon className="w-5 h-5 ml-2 text-white/80" />
              ورود یا ثبت نام
            </Button>
          )}
        </>
      ),
    },
    {
      content: (
        <>
          <div className="lg:hidden">
            <Button
              type="secondary"
              onClick={() => setMobileSidebarIsOpen(true)}
            >
              <MenuIcon className="w-5 h-5 text-white/80" />
            </Button>
          </div>
          <div className="hidden lg:flex">
            <Link to="/my-orders">
              <Button type="secondary">
                <ShoppingCartIcon className="w-5 h-5 text-white/80" />
              </Button>
            </Link>
          </div>
        </>
      ),
    },
  ];

  return (
    <ul className="navbar flex items-center gap-3">
      {tabs?.map(({ content }, index) => (
        <li key={index}>{content}</li>
      ))}
    </ul>
  );
};
