import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Auth } from "../api/customer/auth";
import { useCookies } from "react-cookie";
import { toast } from "../helpers/toast";
import { noahConfig } from "../config/noah";

export const CaptureRedirect = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["noahUserToken"]);
  const [params] = useSearchParams();
  const [code] = params.getAll("code");
  const [state] = params.getAll("state");

  useEffect(() => {
    (async () => {
      try {
        if (typeof cookies?.noahUserToken !== "undefined") {
          return;
        }

        if (typeof code === "undefined") {
          return;
        }

        toast.info("در حال پردازش ..");
        const { data } = await Auth.verifyTwitchLogin({
          code,
          state,
        });
        if (!data) {
          return;
        }
        const production = process.env.REACT_APP_NODE_ENV === "production";
        setCookie("noahUserToken", data?.token, {
          domain: production ? ".dono.gg" : "localhost",
        });
        toast.success("با موفقیت وارد شدید.");
      } catch (e) {
        console.error(e);
      }
    })();
  }, [code]);

  return <></>;
};
