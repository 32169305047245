import { ReactNode } from "react";
import twitchIcon from "../images/twitch.svg";

export const Button = ({
  children,
  type,
  small,
  block,
  disabled,
  ...props
}: {
  children: ReactNode;
  type: string;
  small: boolean;
  block?: boolean;
  disabled?: boolean;
}) => {
  const buttonType = {
    primary: "bg-indigo-600 hover:opacity-80 text-white fill-white",
    secondary: "bg-white/10 hover:bg-white/20 text-white/60",
    gray: "bg-gray-200 hover:bg-gray-300 text-black fill-black",
    violet: "bg-violet-500 hover:bg-violet-600 text-white fill-white",
    indigo: "bg-indigo-500/80 hover:bg-indigo-600/80 text-white fill-white",
    outline:
      "border-white border border-solid hover:bg-white hover:text-black text-white fill-white",
    twitch: "bg-purple-600 hover:opacity-80 text-white fill-white",
    nitroClassic:
      "bg-gradient-to-r from-indigo-500/90 to-indigo-400/80 hover:opacity-80 text-white fill-white",
    nitro:
      "bg-gradient-to-r from-purple-500/90 to-indigo-400/80 hover:opacity-80 text-white fill-white",
  };

  return (
    <div {...props} className={`block ${disabled ? "opacity-40" : ""}`}>
      <div
        className={`${
          buttonType[type] || buttonType.primary
        } inline-flex items-center transition rounded-xl ${
          small ? "px-3.5 py-2 text-15px" : "px-5 py-2.5"
        } ${block ? "w-full text-center justify-center" : ""} cursor-pointer`}
      >
        {type === "twitch" && <img src={twitchIcon} className="ml-2" />}

        {children}
      </div>
    </div>
  );
};
