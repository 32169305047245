import { ChevronLeftIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tag } from "../api/checkout/tag";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { classNames } from "../utils/classNames";
import { ProductCard } from "./Product";
import { GroupProducts } from "./GroupProducts";
import { swiperConfig } from "../config/swiper";

export const MainProducts = ({ title, excludeDirectLink, groupName }) => {
  const [products, setProducts] = useState();

  useEffect(() => {
    (async () => {
      try {
        if (typeof groupName === "undefined") {
          return;
        }

        const { data } = await Tag.getMainTagByName({ name: groupName });
        if (!data) {
          return;
        }
        setProducts(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [groupName]);

  return (
    <div className="w-full mb-4 lg:mb-9">
      <div className="flex items-center justify-between mb-4">
        <h2 className="block font-bold text-base" style={{ color: "#676767" }}>
          {products?.displayName}
        </h2>

        {!excludeDirectLink && (
          <Link to={`/category/${groupName}`}>
            <span className="rounded-full flex items-center gap-x-1 text-sm py-2 font-medium text-indigo-600">
              همه
              <ChevronLeftIcon className="w-6 h-6 ltr:scale-x-[-1]" />
            </span>
          </Link>
        )}
      </div>

      <Swiper
        slidesPerView={5}
        spaceBetween={30}
        className="mySwiper"
        dir="rtl"
        breakpoints={swiperConfig}
      >
        <>
          {products?.groups?.map(
            ({ displayName, description, image, name }) => (
              <SwiperSlide key={name}>
                <div>
                  <Link to={`/collection/${name}`}>
                    <ProductCard
                      {...{ displayName, description, image, name }}
                    />
                  </Link>
                </div>
              </SwiperSlide>
            )
          )}
          {!products?.groups &&
            Array.apply(null, Array(5)).map((arr, index) => (
              <SwiperSlide key={index}>
                <div
                  className={classNames(
                    "bg-custom-gray flex flex-col ga rounded-xl overflow-hidden bg-custom-gray hover:bg-custom-gray/70 transition"
                  )}
                >
                  <div className="skeleton h-44 flex items-center justify-center overflow-hidden" />
                  <div className="p-5 flex flex-col gap-y-3">
                    <div className="skeleton w-32 h-4 overflow-hidden rounded-full"></div>
                    <div className="skeleton w-16 h-4 overflow-hidden rounded-full"></div>
                    <div className="skeleton w-16 h-4 overflow-hidden rounded-full"></div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </>
      </Swiper>
    </div>
  );
};
