import React, { useEffect, useState } from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "../src/config/routes";
import { Toaster } from "react-hot-toast";
import { SelfProvider } from "./hooks/useProvideSelf";
import ReactGA from "react-ga";
import { RouteElementWrapper } from "./components/RouteElementWrapper";

ReactGA.initialize("G-JEWLVTJV98");

export const ShowLoginContext = React.createContext<any>("");

function App() {
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    // Include the Crisp code here, without the <script></script> tags
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = "a8ee88e7-2227-4163-b370-10f85b25a626";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      // @ts-ignore
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  return (
    <SelfProvider>
      <ShowLoginContext.Provider value={{ showLogin, setShowLogin }}>
        <div>
          <Toaster />
        </div>

        <BrowserRouter>
          <Routes>
            {routes.map(({ id, path, Element, subPath }) => (
              <Route
                key={id}
                path={`${path}${subPath ? "/*" : ""}`}
                element={<RouteElementWrapper>{Element}</RouteElementWrapper>}
              />
            ))}
          </Routes>
        </BrowserRouter>
      </ShowLoginContext.Provider>
    </SelfProvider>
  );
}

export default App;
