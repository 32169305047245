import { get, post } from "../../config/customer";

type paramsType = {
  page: number;
  limit: number;
  type?: string;
};

export const defaultParams = ({ page, limit }: paramsType) =>
  `?page=${page}&limit=${limit}`;

export const Customer = {
  getSelf: () => get(`customer/self`),
  getOrders: ({ page, limit }) => get(`order${defaultParams({ page, limit })}`),
  getOrder: (id) => get(`order/${id}`),
  getOrderProduct: ({ orderId, orderProductId }) =>
    get(`order/${orderId}/order-product/${orderProductId}`),
  getOrderProductInformation: ({ orderId, orderProductId }) =>
    get(`order/${orderId}/order-product/${orderProductId}/information`),
};
