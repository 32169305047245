import { Link } from "react-router-dom";
import { SidebarTab } from "./SidebarTab";
import { routes } from "../config/routes";
import { SideContainer } from "./SideContainer";

export const Sidebar = () => {
  return (
    <div>
      <SideContainer>
        <ul className="w-full list-none">
          {routes?.map(({ title, id, path, hidden, fakePath }) => {
            if (hidden) {
              return;
            }

            return <SidebarTab key={id} {...{ path, title, fakePath }} />;
          })}
        </ul>
      </SideContainer>
    </div>
  );
};
