import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Customer } from "../api/customer/customer";
import { Twitch } from "../api/twitch";

export const selfContext = React.createContext<any>("");

export const SelfProvider = ({ children }) => {
  const user = useProvideSelf();

  return <selfContext.Provider value={user}>{children}</selfContext.Provider>;
};

const useProvideSelf = () => {
  const [user, setUser] = useState();
  const [twitchUser, setTwitchUser] = useState();
  const [cookies] = useCookies(["noahUserToken"]);

  useEffect(() => {
    (async () => {
      try {
        if (!cookies?.noahUserToken) {
          return;
        }

        const { data } = await Customer.getSelf();
        if (!data) {
          return;
        }
        const { metadata } = data;
        setUser(metadata);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [cookies?.noahUserToken]);

  return { user, twitchUser };
};
