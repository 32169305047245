import { useEffect, useState } from "react";
import { Customer } from "../../api/customer/customer";
import { Order } from "../../api/checkout/order";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VerifyOrderProduct } from "./VerifyOrderProduct";
import { Button } from "../../components/Button";
import { Layout } from "../Layout";

export const Verify = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState();
  const [query, setQuery] = useState();
  const [orderProducts, setOrderProducts] = useState<any[]>();
  const orderId = searchParams.get("orderId");
  const navigate = useNavigate();

  const getOrderStatus = async () => {
    try {
      if (status === "COMPLETED") {
        return;
      }

      const { data } = await Customer.getOrder(orderId);

      if (!data) {
        return;
      }
      const { status: responseStatus } = data;
      if (!responseStatus) {
        return;
      }

      setStatus(responseStatus);
      return responseStatus;
    } catch (e) {
      console.error(e);
    }
  };

  const getOrderProductStatus = async () => {
    try {
      const { data } = await Customer.getOrder(orderId);
      if (!data) {
        return;
      }
      setOrderProducts(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!orderId) {
          return;
        }

        const { data } = await getOrderStatus();

        if (!data) {
          return;
        }

        const to = setInterval(getOrderStatus, 3000);

        return () => {
          clearTimeout(to);
        };
      } catch (e) {
        console.error(e);
      }
    })();
  }, [orderId]);

  useEffect(() => {
    (async () => {
      try {
        if (!status) {
          return;
        }

        await getOrderProductStatus();

        const to = setInterval(getOrderProductStatus, 3000);

        return () => {
          clearTimeout(to);
        };
      } catch (e) {
        console.error(e);
      }
    })();
  }, [status]);

  const dynamicHeroTitle = () => {
    if (status === "COMPLETED") {
      return "سفارش شما با موفقیت انجام شد.";
    }
    if (status === "PROCESSING") {
      return "سفارش شما در حال پردازش می باشد.";
    }
    if (status === "PENDING_PAYMENT") {
      return "در حال تایید پرداخت شما هستیم.";
    }
    if (status === "FAILED") {
      return "سفارش شما با خطا مواجه شد.";
    }
    return "در حال دریافت وضعیت سفارش ..";
  };

  const handleRedirectOrder = () => {
    navigate(`/verify?orderId=${query}`);
  };

  return (
    <Layout>
      <div
        className={`bg-white/5 rounded-lg px-7 py-16 verify-bg flex items-center justify-center ${
          orderId ? "h-64" : ""
        } mb-10`}
      >
        <div className="flex flex-col gap-y-4 items-center">
          {orderId ? (
            <>
              <h2 className="font-bold text-2xl">{dynamicHeroTitle()}</h2>
              <p className="opacity-50 max-w-md">شناسه سفارش: {orderId}</p>
            </>
          ) : (
            <>
              <h2 className="font-bold text-2xl">پیگیری سفارش</h2>
              <p className="opacity-50 max-w-md">
                در صورتی که سایت سفارشی ثبت کرده اید و قصد دارید از آخرین وضعیت
                انجام آن مطلع شوید، کد سفارش خود را وارد کنید.
              </p>
              <input
                type="text"
                placeholder="کد سفارش را وارد کنید .."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="bg-transparent cursor-pointer border-2 rounded-xl h-12 px-6 border-white/10 placeholder-white/40 w-full focus:bg-black/20 focus:border-indigo-500 focus:ring-indigo-500"
              />
              <div className="w-full">
                <Button type="primary" block onClick={handleRedirectOrder}>
                  پیگیری سفارش
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      {orderProducts && (
        <span className="block mb-6 font-bold" style={{ color: "#676767" }}>
          <h3>محصولات سفارش: {orderProducts?.products?.length}</h3>
        </span>
      )}

      {orderProducts && (
        <div className="grid grid-cols-2 mb-12">
          {orderProducts?.products?.map((props) => (
            <VerifyOrderProduct {...{ ...props, orderId }} />
          ))}
        </div>
      )}
    </Layout>
  );
};
