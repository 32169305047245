import { Button } from "./Button";
import { useCookies } from "react-cookie";
import { ShowLoginContext } from "../App";
import { useContext, useState } from "react";
import { Login } from "../pages/Login";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "../helpers/toast";
import { selfContext } from "../hooks/useProvideSelf";
import { ShoppingCartIcon, UserIcon, MenuIcon } from "@heroicons/react/outline";
import { HeaderTabs } from "./HeaderTabs";
import { MobileSidebar } from "./MobileSidebar";

export const Navbar = () => {
  const { showLogin, setShowLogin } = useContext(ShowLoginContext);
  const [params] = useSearchParams();
  const manualLoginTrigger = params.get("triggerLogin");
  const [mobileSidebarIsOpen, setMobileSidebarIsOpen] = useState(false);
  const [cookies, setCookie] = useCookies();

  return (
    <div className="flex justify-between lg:flex-grow items-center">
      <div className="search flex-grow max-w-md hidden lg:flex">
        <input
          type="text"
          placeholder="دنبال چی هستید؟"
          className="bg-transparent cursor-pointer border-2 rounded-xl h-14 px-6 border-white/10 placeholder-white/40 w-full focus:bg-black/20 focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>
      <HeaderTabs {...{ setMobileSidebarIsOpen }} />
      <div className="absolute">
        {(showLogin || manualLoginTrigger) && (
          <div className="relative z-30">
            <Login setShowLogin={setShowLogin} setCookie={setCookie} />
          </div>
        )}
      </div>
      <div className="absolute">
        <MobileSidebar
          isOpen={mobileSidebarIsOpen}
          setIsOpen={setMobileSidebarIsOpen}
        />
      </div>
    </div>
  );
};
