import logo from "../images/logo.svg";
import { Navbar } from "./Navbar";
import { Link } from "react-router-dom";
import { SideContainer } from "./SideContainer";

export const Header = () => {
  return (
    <div className="header flex justify-between lg:gap-14 items-center mb-10">
      <SideContainer>
        <div className="header-logo">
          <Link to="/" className="flex">
            <img src={logo} />
            <span className="mr-3 text-lg font-semibold">دونو شاپ</span>
          </Link>
        </div>
      </SideContainer>
      <Navbar />
    </div>
  );
};
