import footerLogo from "../images/footer-logo.svg";
import { Container } from "./Container";
import namad from "../images/namad.png";
import { Link } from "react-router-dom";
import { toast } from "../helpers/toast";
import { Features } from "./Features";

export const Footer = () => {
  return (
    <>
      <div className="leading-7 bg-white/5 rounded-lg flex flex-col justify-between flex-grow py-8 md:py-12 px-12">
        <div className="flex justify-between">
          <div className="flex justify-start md:justify-center col-span-4 md:col-span-1 mb-10 md:mb-12">
            <div>
              <img src={footerLogo} className="mb-1" />
              <span className="text-sm font-medium opacity-60">
                اتوماسیون پرداخت های ارزی
              </span>
            </div>
          </div>
          <div className="flex justify-start md:justify-center">
            <div>
              <h3 className="font-bold mb-2">دونو</h3>
              <ul className="text-sm opacity-80 leading-8">
                <li>درباره ما</li>
                <li>
                  <Link to="/privacy-policy">حریم خصوصی</Link>
                </li>
                <li>وبلاگ</li>
                <li
                  className="cursor-pointer"
                  onClick={() =>
                    toast.info(
                      "برای تماس با ما از آیکن پایین سمت راست صفحه استفاده کنید."
                    )
                  }
                >
                  ارتباط با ما
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-start md:justify-center">
            <div>
              <h3 className="font-bold mb-2">دونو</h3>
              <ul className="text-sm opacity-80 leading-8">
                <li>درباره ما</li>
                <li>
                  <Link to="/privacy-policy">حریم خصوصی</Link>
                </li>
                <li>وبلاگ</li>
                <li
                  className="cursor-pointer"
                  onClick={() =>
                    toast.info(
                      "برای تماس با ما از آیکن پایین سمت راست صفحه استفاده کنید."
                    )
                  }
                >
                  ارتباط با ما
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-center">
            <div>
              <img src={namad} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
