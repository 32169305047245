import { get, post } from "../../config/customer";

export const Auth = {
  login: (email) => post(`auth/login/EMAIL`, { metadata: {email} }),
  loginWithTwitch: ({ redirectUrl }) =>
    post(`auth/login/TWITCH`, { metadata: {redirectUrl} }),
  verifyLogin: ({ id, code }) => post(`auth/login/EMAIL/verify`, {metadata: { id, code }}),
  verifyTwitchLogin: ({ code, state }) =>
    post(`auth/login/TWITCH/verify`, {metadata: { code, state }}),
};
