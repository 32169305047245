import React, { useRef } from "react";
import { CaptureRedirect } from "../components/CaptureRedirect";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar";
import { Features } from "../components/Features";
import { Footer } from "../components/Footer";
import { swiperConfig } from "../config/swiper";

export const Layout = ({ children }) => {
  return (
    <div className="App px-5 xl:px-10 py-4 xl:py-6 overflow-hidden w-full">
      <CaptureRedirect />
      <Header />
      <div className="flex justify-between gap-8 lg:gap-14 xl:gap-14 overflow-x-auto">
        <div className="hidden lg:flex">
          <Sidebar />
        </div>
        <div className="flex-grow w-full">
          <>
            {children}
            <Footer />
          </>
        </div>
      </div>
    </div>
  );
};
