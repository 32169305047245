import delivery from "../images/delivery.svg";
import support from "../images/support.svg";
import refund from "../images/refund.svg";
import trust from "../images/trust.svg";

export const Features = () => {
  return (
    <div className="flex justify-between px-10 mb-10">
      <div className="flex flex-col items-center gap-y-2">
        <img src={delivery} className="mb-2 w-12" />
        <h3 className="text-lg font-semibold">تحویل سریع سفارشات</h3>
        <p className="text-sm opacity-50 w-44 text-center">
          انجام سفارش شما در سریع ترین حالت ممکن
        </p>
      </div>
      <div className="flex flex-col items-center gap-y-2">
        <img src={support} className="mb-2 w-12" />
        <h3 className="text-lg font-semibold">پشتیبانی همیشه آنلاین</h3>
        <p className="text-sm opacity-50 w-44 text-center">
          پشتیبانی همیشه آماده خدمتگزاری به شماست
        </p>
      </div>
      <div className="flex flex-col items-center gap-y-2">
        <img src={refund} className="mb-2 w-12" />
        <h3 className="text-lg font-semibold">ضمانت بازگشت وجه</h3>
        <p className="text-sm opacity-50 w-44 text-center">
          بازگشت کامل وجه در صورت بروز هرگونه مشکل
        </p>
      </div>
      <div className="flex flex-col items-center gap-y-2">
        <img src={trust} className="mb-2 w-12" />
        <h3 className="text-lg font-semibold">مورد اعتماد صد ها مشتری</h3>
        <p className="text-sm opacity-50 w-44 text-center">
          قبل از شما، صد ها مشتری دیگر به ما اعتماد کرده اند
        </p>
      </div>
    </div>
  );
};
