import { Dialog, Transition } from "@headlessui/react";
import { HeaderTabs } from "./HeaderTabs";
import { routes } from "../config/routes";
import { Route } from "react-router-dom";
import React, { Fragment } from "react";
import { SidebarTab } from "./SidebarTab";

export const MobileSidebar = ({ isOpen, setIsOpen }) => {
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={(e) => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/80 backdrop-blur">
              <div className="p-10">
                <ul className="w-full list-none">
                  {routes?.map(({ title, id, path, hidden }) => {
                    if (hidden) {
                      return;
                    }

                    return <SidebarTab key={id} {...{ path, title }} />;
                  })}
                </ul>
              </div>
            </Dialog.Overlay>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
};
