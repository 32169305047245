import { Layout } from "./Layout";

export const Privacy = () => {
  return (
    <Layout>
      <div
        className={`bg-white/5 rounded-lg px-7 py-10 verify-bg flex items-center justify-center h-64 mb-10`}
      >
        <div className="flex flex-col gap-y-4 items-center">
          {
            <>
              <h2 className="font-bold text-2xl">حریم خصوصی</h2>
            </>
          }
        </div>
      </div>
    </Layout>
  );
};
