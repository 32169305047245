import { Banner } from "../components/Banner";
import { Features } from "../components/Features";
import React from "react";
import { Layout } from "./Layout";
import { MainProducts } from "../components/MainProducts";
import { GroupProducts } from "../components/GroupProducts";

export const Home = () => {
  return (
    <>
      <Layout>
        <div className="bg-rose-600 rounded-xl py-4 px-4 mb-4">
          فروش سایت در حال حاضر متوقف شده است، در کنار شما هستیم.
        </div>
        <Banner />
        <MainProducts title="ولورانت" groupName="PC_GAMES" />
        <hr class="opacity-10 my-16"></hr>
        <Features />
      </Layout>
    </>
  );
};
