import { get, post } from "../../config/checkout";

type paramsType = {
  page: number;
  limit: number;
  type?: string;
};

export const defaultParams = ({ page, limit }: paramsType) =>
  `?page=${page}&limit=${limit}`;

export const Dynamic = {
  Content: {
    getAll: ({ hookName, page, limit }) =>
      get(`dynamic/content/${hookName}${defaultParams({ page, limit })}`),
  },
};
