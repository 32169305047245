import { useEffect, useState } from "react";
import { classNames } from "../utils/classNames";

export const ProductOption = ({
  displayTitle,
  title,
  options,
  setSelectedVariant,
  value,
}) => {
  const setSelectedOption = (option) => {
    setSelectedVariant((prevState) => {
      const newState = { ...prevState };
      newState[title] = option;
      return newState;
    });
  };

  return (
    <div className="flex flex-col gap-y-2 text-sm">
      <span className="font-medium text-white opacity-50">{displayTitle}</span>
      <div className="flex items-center gap-2 flex-wrap">
        {options &&
          options?.map((option) => {
            const active = value === option;

            return (
              <div
                className={classNames(
                  "border py-1 px-4 flex items-center justify-center rounded-full font-medium cursor-pointer price",
                  active &&
                    "border-indigo-600 bg-indigo-600 text-primary hover:opacity-90",
                  !active && "border-white/50 text-white/50 hover:bg-white/5"
                )}
                onClick={() => setSelectedOption(option)}
                key={option}
              >
                {option}
              </div>
            );
          })}
      </div>
    </div>
  );
};
