import { get, post } from "../../config/checkout";
import { defaultParams } from "../customer/customer";

export const Tag = {
  getMainTagByName: ({ name, page = 1, limit = 5 }) =>
    get(`tag/MAIN/${name}${defaultParams({ page, limit })}`),
  getAllMainTags: ({ groupsLimit, page = 1, limit = 10 }) =>
    get(
      `tag/MAIN${defaultParams({ page, limit })}${
        (groupsLimit && `&groupsLimit=${groupsLimit}`) || ""
      }`
    ),
  getGroupTagByName: (name) => get(`tag/GROUP/${name}`),
  getAllGroupTags: ({ query, page = 1, limit = 10 }) =>
    get(
      `tag/GROUP/${defaultParams({ page, limit })}${
        (query && `&query=${query}`) || ""
      }`
    ),
};
