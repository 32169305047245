import axios, { AxiosError } from "axios";
import { errorMonitor } from "stream";
import Cookies from "universal-cookie";
import { toast } from "../helpers/toast";
import { noahConfig } from "./noah";

const cookies = new Cookies();
const userToken = () => "Bearer " + cookies?.get("noahUserToken");
const production = process.env.REACT_APP_NODE_ENV === "production";

const errorHandler = (err: AxiosError) => {
  const { error, message, statusCode } = err?.response?.data;

  if (statusCode === 403) {
    cookies.remove("noahUserToken", {
      domain: production ? ".dono.gg" : "localhost",
    });
    window.location.reload();
  }

  if (error.includes("TODO_EXCEPTION_YOU_NEED_TO_WAIT_MORE")) {
    return;
  } else if (error) {
    toast.error(error);
  } else {
    toast.error(`مشکلی پیش آمد`);
  }

  return Promise.reject({ ...error });
};

const instance = axios.create({
  baseURL: `https://customer-api${
    process.env.REACT_APP_NODE_ENV === "production" ? "" : "-staging"
  }.9ah.ir/v1`,
  timeout: 60000,
  headers: {
    "noah-partner-id": noahConfig.NOAH_PARTNER_ID,
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => errorHandler(error)
);

instance.interceptors.request.use(
  function (config) {
    if (cookies?.get("noahUserToken")) {
      config.headers["Authorization"] = userToken();
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const { get, post, put, delete: remove, patch } = instance;

export { get, post, put, remove, patch };
