import sampleBanner from "../images/banner.jpg";
import { ChevronLeftIcon } from "@heroicons/react/outline";

export const Banner = () => {
  return (
    <div
      className="rounded-xl w-full bg-cover h-40 md:h-80 flex flex-grow justify-center md:justify-start items-center bg-center p-6 md:p-12 mb-10"
      style={{ backgroundImage: `url(${sampleBanner})` }}
    >
      <div className="flex flex-col banner-text-container items-center px-5 py-4 md:px-9 md:py-7 gap-y-2">
        <h1 className="text-2xl font-semibold">خرید پین بازی های آنلاین</h1>
        <p className="flex text-lg items-center opacity-60">
          تحویل آنی و تضمین قیمت{" "}
          <ChevronLeftIcon className="w-5 h-5 text-white" />
        </p>
      </div>
    </div>
  );
};
