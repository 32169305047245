export const swiperConfig = {
    0: {
      slidesPerView: 2.4,
      spaceBetween: 16,
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 3.5,
      spaceBetween: 30,
    },
    1440: {
      slidesPerView: 5.8,
      spaceBetween: 30,
    },
  }