import { Link } from "react-router-dom";
import { classNames } from "../utils/classNames";

export const ProductCard = ({
  displayName,
  description,
  image,
  name,
  instant,
  colorfulSubtitle,
}) => {
  return (
    <div className="flex flex-col rounded-xl overflow-hidden bg-custom-gray hover:bg-custom-gray/70 transition">
      <div className="h-52 flex items-center justify-center overflow-hidden">
        <img
          className="object-cover h-full w-full"
          src={image.replace("paxit", "9ah")}
        />
      </div>
      <div className="p-5 flex flex-col gap-y-1">
        <h2 className="text-lg font-semibold">{displayName}</h2>
        {instant && (
          <span className="opacity-50 text-15px block mb-1">تحویل آنی</span>
        )}
        {name && (
          <span
            className={classNames(
              "text-right uppercase",
              colorfulSubtitle
                ? "text-indigo-600 font-bold"
                : "text-white/30 font-medium text-sm"
            )}
          >
            {name}
          </span>
        )}
      </div>
    </div>
  );
};
