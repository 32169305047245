import { Order } from "../api/checkout/order";
import { toast } from "./toast";

export const createOrder = async ({
  id,
  metadata,
  paymentMethodId,
}: {
  id: string;
  metadata: any;
  paymentMethodId: string;
}) => {
  try {
    const { data } = await Order.create({ id, metadata, paymentMethodId });

    if (!data) {
      return;
    }

    const { payment } = data;

    toast.success("در حال هدایت به درگاه بانکی ..");

    if (payment?.url) {
      return setTimeout(() => window.location.assign(payment?.url), 1000);
    }
  } catch (e) {
    console.error(e);
  }
};
